<template>
  <div class="footer d-flex flex-column align-center white--text pt-3">
      <div :style="`position: relative; ${$vuetify.breakpoint.mdAndUp ? 'width: 80%; min-width: 850px' : 'width: 100%'}`">
          <div :style="`position: absolute; top: -28px; z-index: 100; ${$vuetify.breakpoint.mdAndUp ? 'right: 100px' : 'right: 50px'}`">
              <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-on="on"
                          v-bind="attrs"
                          fab
                          color="secondary"
                          @click="$vuetify.goTo(0)"
                      >
                        <v-icon large>mdi-chevron-up</v-icon>
                      </v-btn>
                  </template>
                  <span>Scroll to top</span>
              </v-tooltip>
          </div> <!-- Go Up Btn -->
          <div class="py-4 px-8 text-h6">{{ selectedPage.title }}</div>
          <div class="divider"></div>
          <div
              id="contactUs"
              :class="`pa-2 py-4 d-flex ${$vuetify.breakpoint.lgAndUp ? 'justify-space-between' : 'flex-column justify-center align-center'}`"
          >
              <div :class="`d-flex justify-center align-center ${$vuetify.breakpoint.smAndUp ? '' : 'flex-column'}`">
                  <img
                      height="200"
                      alt="Call Us"
                      src="/images/contact.svg"
                      style="margin-top: 25px"
                  >
                  <div
                      :class="`text-h6 mt-3 font-weight-thin d-flex flex-column ${$vuetify.breakpoint.mdAndUp ? 'justify-space-around' : 'align-center justify-center'}`"
                      :style="`${$vuetify.breakpoint.mdAndUp ? '' : 'width: 100%'}`"
                  >
                      <div class="d-flex align-start pb-4">
                          <v-icon
                              left
                              large
                              color="white"
                          >mdi-phone</v-icon>
                          <div class="pl-2">Call Us: <strong>015 307 5587</strong></div>
                      </div>
                      <div class="d-flex align-start pb-4">
                          <v-icon
                              left
                              large
                              color="white"
                          >mdi-mail</v-icon>
                          <div class="pl-2">Mail Us: <strong>info@smitk.co.za</strong></div>
                      </div>
                      <div class="d-flex align-start pb-4">
                          <v-icon
                              left
                              large
                              color="white"
                          >mdi-map-marker</v-icon>
                          <div class="pl-2">
                              Find Us:
                              <strong>
                                  10 Windsor Street
                                  <div class="d-flex">
                                      Tzaneen
                                      <br v-if="$vuetify.breakpoint.lgAndUp">
                                      <div v-else>&nbsp;|&nbsp;</div>
                                      0850
                                  </div>
                              </strong>
                          </div>
                      </div>
                  </div>
              </div>
              <div
                  v-if="!mailSent"
                  class="d-flex flex-column justify-center"
                  :style="`${$vuetify.breakpoint.mdAndUp ? 'width: 250px' : 'width: 97%'}`"
              >
                  <div class="text-h5 pb-4">Or let us <strong>Call You</strong></div>
                  <v-form :disabled="sendingMail" ref="sendMail">
                      <v-text-field
                          label="Name"
                          dense
                          dark
                          outlined
                          class="pt-2"
                          hide-details="auto"
                          placeholder="e.g. John Smith"
                          :rules="[validationRules.required]"
                          v-model="name"
                          full-width
                      ></v-text-field>
                      <v-text-field
                          label="Email Address"
                          dense
                          dark
                          outlined
                          class="pt-2"
                          hide-details="auto"
                          placeholder="e.g. info@smitk.co.za"
                          :rules="[validationRules.required, validationRules.email]"
                          v-model="contactEmail"
                      ></v-text-field>
                      <v-text-field
                          label="Contact No."
                          dense
                          dark
                          outlined
                          class="pt-2"
                          hide-details="auto"
                          placeholder="e.g. 015 307 5587"
                          :rules="[validationRules.required, validationRules.cellNo]"
                          v-model="contactNo"
                      ></v-text-field>
                      <div class="pt-2 text-center">
                          <v-btn
                              outlined
                              color="white"
                              :disabled="sendingMail"
                              :loading="sendingMail"
                              @click="sendEmail"
                          >Call Me</v-btn>
                      </div>
                  </v-form>
              </div>
              <div
                  v-else
                  class="d-flex flex-column justify-center align-center"
                  style="width: 250px"
              >
                  <img
                      width="200"
                      alt="thank you"
                      src="/images/thank-you.svg"
                  >
                  <div class="text-h5 pb-4"><b>Thank You!</b></div>
                  <div class="text-h5 pb-4">We will be in <b>contact</b>.</div>
              </div>
              <div :class="`${$vuetify.breakpoint.mdAndUp ? '' : 'mt-5'}`">
                  <v-card style="position: relative">
                      <div
                          :style="`height: 250px; ${$vuetify.breakpoint.mdAndUp ? 'width: 500px' : `width: ${getScreenWidth - 25}px`}`"
                          ref="footerMap"
                      ></div>
                      <v-card
                          class="ma-2 d-flex align-center pa-1"
                          style="position: absolute; top: 0; left: 0"
                      >
                          <img
                              width="50"
                              alt="logo"
                              src="/images/logo.svg"
                          />
                          <div class="pl-3 pr-2 d-flex flex-column justify-space-around">
                              <div class="text-subtitle-2">Smit & Kie Brokers</div>
                              <div class="text-caption">10 Windsor Street | Tzaneen | 0850</div>
                              <div class="text-caption blue--text">
                                  <a
                                      target="_blank"
                                      href="https://goo.gl/maps/Gt1pb2oRqqCwUHM86"
                                      style="text-decoration: none"
                                  >
                                      <strong>Get Directions</strong>
                                  </a>
                              </div>
                          </div>
                      </v-card>
                  </v-card>
              </div>
          </div>
          <div
              v-if="$vuetify.breakpoint.mdAndUp"
              class="pt-4 pb-2 d-flex justify-space-around"
          >
              <v-btn
                      v-for="page in pages"
                      :key="page.id"
                      text
                      color="white"
                      @click="setCurPage(page.id)"
              >
                  {{page.title}}
              </v-btn>
          </div>
          <div class="divider"></div>
      </div>
      <div
          class="text-center mt-4 pa-1 flex-grow-1"
          style="width: 100%; background: rgba(0,0,0,0.5)"
      >
          © {{ new Date().getFullYear() }} Smit & Kie. FSP 11184. All Rights Reserved.
      </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { Loader } from "@googlemaps/js-api-loader"

export default {
  data() {
        return {
            console,
            name: null,
            contactEmail: null,
            contactNo: null,
            validationRules: {
                required: value => !!value || 'Required Value',
                min13Characters: value => !!value && value.length >= 13 || 'Minimum 13 Characters',
                notZero: value => value === '0' || value === '' || value == null ? 'Value can not be zero' : true,
                notNull: value => value !== null || 'Required Value',
                min6Characters: value => value !== null && value.split('').length >= 6 || 'Minimum 6 characters',
                specialCharacter: value => value !== null && /[^A-Za-z0-9]/.test(value) || 'Minimum 1 special character (eg. !@#$%)',
                upperCase: value => value !== null && /[A-Z]/.test(value) || 'Minimum 1 uppercase character',
                lowerCase: value => value !== null && /[a-z]/.test(value) || 'Minimum 1 lowercase character',
                numeric: value => value !== null && /[0-9]+/.test(value) || 'Minimum 1 numerical character',
                numberOnly: value => value !== null && /^[\d\\.]+$/.test(value) || 'Only numbers allowed',
                wholeNumberOnly: value => value !== null && /^[\d]+$/.test(value) || 'Only whole numbers allowed',
                email: value => {
                  if (!!value && value !== null && value.length > 0) {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Invalid Email Address';
                  } else {
                    return 'Please enter a valid email address'
                  }
                },
                cellNo: value => value !== null && /[0-9+ ][^A-Za-z]{9,}/.test(value) || 'Please enter a valid phone number',
            },
            sendingMail: false,
            mailSent: false,
        }
  },
  methods: {
      ...mapActions(['setCurPage']),
      sendEmail() {
        if (this.$refs.sendMail.validate()) {
          this.sendingMail = true;
          let mailBody = `<table style="font-family: sans-serif;">
                      <tr>
                          <td style="padding: 8px" colSpan="2" style="font-size: 18px">New Contact Request From Website</td>
                      </tr>
                      <tr>
                          <td style="padding: 8px"><strong>name:</strong></td>
                          <td style="padding: 8px">${this.name}</td>
                      </tr>
                      <tr>
                          <td style="padding: 8px"><strong>Contact No:</strong></td>
                          <td style="padding: 8px">${this.contactNo}</td>
                      </tr>
                      <tr>
                          <td style="padding: 8px"><strong>Contact Email:</strong></td>
                          <td style="padding: 8px">${this.contactEmail}</td>
                      </tr>
                  </table>`
          Email.send({
            SecureToken : "0a0cd794-a296-40fe-b688-973763c30498",
            To : this.contactEmailAddress,
            From : "website@smitk.co.za",
            Subject : "[SK WEBSITE] New Quote",
            Body : mailBody
          }).then(() => {
            this.sendingMail = false;
            this.mailSent = true;
          });
        }
      },
  },
  computed: {
      ...mapGetters(['curPage', "pages", "contactEmailAddress"]),
      selectedPage() {
          return this.pages.filter(obj => obj.id === this.curPage).pop()
      },
      getScreenWidth() {
          return window.screen.width;
      }
  },
  created() {
    const vm = this
    const loader = new Loader({
      apiKey: "AIzaSyAH5KxJ9adr97fcd8XKp87XJ3Fuh-6oQ8w",
      version: "weekly",
    });

    loader.load().then(() => {

      const skLocation = {
        "lat": -23.833778799797784,
        "lng": 30.15719999745618
      }

      const centLocation = {
        "lat": -23.833778799797784,
        "lng": 30.15669990
      }

      let mapEl = this.$refs.footerMap//document.getElementById("map")


      // The map, centered at Uluru
      const map = new google.maps.Map(mapEl, {
        zoom: 18,
        center: centLocation,
        disableDefaultUI: true,
      });

      let retro = [
        { elementType: "geometry", stylers: [{ color: "#ebe3cd" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#523735" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#f5f1e6" }] },
        {featureType: "administrative", elementType: "geometry.stroke", stylers: [{ color: "#c9b2a6" }],},
        {featureType: "administrative.land_parcel", elementType: "geometry.stroke", stylers: [{ color: "#dcd2be" }],},
        {featureType: "administrative.land_parcel", elementType: "labels.text.fill", stylers: [{ color: "#ae9e90" }],},
        {featureType: "landscape.natural", elementType: "geometry", stylers: [{ color: "#dfd2ae" }],},
        {featureType: "poi", elementType: "geometry", stylers: [{ color: "#dfd2ae" }],},
        {featureType: "poi", elementType: "labels.text.fill", stylers: [{ color: "#93817c" }],},
        {featureType: "poi.park", elementType: "geometry.fill", stylers: [{ color: "#a5b076" }],},
        {featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ color: "#447530" }],},
        {featureType: "road", elementType: "geometry", stylers: [{ color: "#f5f1e6" }],},
        {featureType: "road.arterial", elementType: "geometry", stylers: [{ color: "#fdfcf8" }],},
        {featureType: "road.highway", elementType: "geometry", stylers: [{ color: "#f8c967" }],},
        {featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ color: "#e98d58" }],},
        {featureType: "road.highway.controlled_access", elementType: "geometry", stylers: [{ color: "#e98d58" }],},
        {featureType: "road.highway.controlled_access", elementType: "geometry.stroke", stylers: [{ color: "#db8555" }],},
        {featureType: "road.local", elementType: "labels.text.fill", stylers: [{ color: "#806b63" }],},
        {featureType: "transit.line", elementType: "geometry", stylers: [{ color: "#dfd2ae" }],},
        {featureType: "transit.line", elementType: "labels.text.fill", stylers: [{ color: "#8f7d77" }],},
        {featureType: "transit.line", elementType: "labels.text.stroke", stylers: [{ color: "#ebe3cd" }],},
        {featureType: "transit.station", elementType: "geometry", stylers: [{ color: "#dfd2ae" }],},
        {featureType: "water", elementType: "geometry.fill", stylers: [{ color: "#b9d3c2" }],},
        {featureType: "water", elementType: "labels.text.fill", stylers: [{ color: "#92998d" }],},
      ]

      map.setOptions({ styles: retro });

      const marker = new google.maps.Marker({
        position: skLocation,
        map: map,
        query: 'Smit And Kie Brokers',
      });
    });
  },
}
</script>

<style scoped>
    .divider {
        height: 2px;
        width: 100%;
        background: var(--v-secondary-lighten1);
    }
    .footer {
        background: radial-gradient(832.71px at 20.85% 77.15%,#a81916 0,#bf5654 100%);
    }

    #map-canvas{
        height: 500px;
        width: 100%;
        max-width: 100%;
        position: relative;
    }

    .placeDiv {
        z-index: 9999;
        position: absolute;
    }

    .map-container {
        position: relative;
    }
</style>