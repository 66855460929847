<template>
    <div class="d-flex pt-4">
        <div class="d-md-flex">
            <div
                :class="`${ $vuetify.breakpoint.lgAndUp ? '' : 'd-flex justify-center' }`"
                :style="`${ $vuetify.breakpoint.lgAndUp ? 'width: 40%' : 'width: 100%' }`"
            >
                <img
                    :width="`${ $vuetify.breakpoint.lgAndUp ? '' : '400' }`"
                    alt="products"
                    src="/images/products.svg"
                />
            </div>
            <div class="d-flex justify-center flex-wrap pr-md-12" :style="`${ $vuetify.breakpoint.mdAndUp ? 'width: 60%' : 'width: 100%' }`">
                <div
                    v-for="(product, i) in products"
                    :key="i"
                    class="pa-4 d-flex flex-column align-center"
                    @click="selectedProductIndex = selectedProductIndex === i ? null : i"
                >
                    <v-menu
                        bottom
                        offset-y
                        nudge-top="30"
                        nudge-left="250"
                        :min-width="`${ $vuetify.breakpoint.mdAndUp ? '500' : '375' }`"
                        :max-width="`${ $vuetify.breakpoint.mdAndUp ? '500' : '375' }`"
                    >
                        <template v-slot:activator="{ on }">
                            <v-hover v-slot="{ hover }">
                                <v-icon
                                    large
                                    style="width: 60px; height: 60px; border-radius: 30px; cursor: pointer; border: 2px solid var(--v-secondary-base)"
                                    :class="hover ? 'elevation-5 secondary' : null"
                                    :color="hover ? 'white' : 'secondary'"
                                    v-on="on"
                                >{{ product.icon }}</v-icon>
                            </v-hover>
                            <div class="pt-2 text-center" style="width: 100px">{{product.title}}</div>
                        </template>
                        <v-card class="fill-height d-flex align-center pa-3 primary white--text">
                            <div>
                                <v-icon
                                    large
                                    style="width: 60px; height: 60px; border-radius: 30px; cursor: pointer; border: 2px solid var(--v-white-base)"
                                    color="white"
                                >{{ product.icon }}</v-icon>
                            </div>
                            <div class="pl-3 flex-grow-1">
                                <div class="text-h6 text-center">{{product.title}}</div>
                                <div class="pt-2 body-2 text-center">{{product.description}}</div>
                            </div>
                        </v-card>
                    </v-menu>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            selectedProductIndex: null
        }
    },
    components: {
    },
    computed: {
        ...mapGetters(['products'])
    }
}
</script>

<style scoped>
    .home {
        background-image: url("/images/background.svg");
        background-position: bottom;
        background-size: contain;
    }
</style>