import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {customProperties: true},
        themes: {
            options: {
                customProperties: true,
            },
            light: {
                // primary: '#2E7D32',
                // secondary: '#FFA000',
                primary: '#a81916',
                secondary: '#c17070',
            },
        }
    }
});
