import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

const store = new Vuex.Store({
    state() {
        return {
            curPage: 'home',
            pages: [
                {id: 'home', title: 'Home'},
                {id: 'products', title: 'Products'},
                {id: 'team', title: 'Team'},
                {id: 'findBroker', title: 'Find a Broker'},
                {id: 'becomePartner', title: 'Become a Partner'},
                {id: 'additionalDocs', title: 'Legal Docs'},
                {id: 'otherDocs', title: 'Docs'},
                {id: 'smitAdvisors', title: 'Smit Advisors'},
                {id: 'news', title: 'News'},
                {id: 'about', title: 'About'},
            ],
            offices: [],
            brokers: [],
            products: [],
            team: [],
            documents: [],
            otherDocs: [],
            quotesEmailAddress: null,
            contactEmailAddress: null,
            newBrokerEmailAddress: null,
            newBrokerContactNo: null,
            news: []
        }
    },
    mutations: {
        setCurPage(state, payload) {
            state.curPage = payload
        },
        init(state, payload) {
            state.offices = payload.offices || [];
            state.brokers = payload.brokers || [];
            state.products = payload.products || [];
            state.team = payload.team || [];
            state.documents = payload.documents || [];
            state.otherDocs = payload.otherDocs || [];
            state.news = payload.news || [];
            state.quotesEmailAddress = payload.quotesEmailAddress;
            state.contactEmailAddress = payload.contactEmailAddress;
            state.newBrokerEmailAddress = payload.newBrokerEmailAddress;
            state.newBrokerContactNo = payload.newBrokerContactNo
        }
    },
    actions: {
        setCurPage(context, payload) {
            console.log(payload)

            context.commit('setCurPage', payload)
        },
        setDynamicData(context, payload) {
            context.commit('init', payload)
        }
    },
    getters: {
        curPage(state) {return state.curPage},
        pages(state) {return state.pages},
        offices(state, getters) {
            return state.offices.map(obj => {
                return {
                    ...obj,
                    brokers: getters.brokers.filter(broker => broker.officeKey === obj.key)
                }
            })
        },
        brokers(state) {return state.brokers},
        news(state) {return state.news},
        documents(state) {return state.documents},
        otherDocs(state) {return state.otherDocs},
        products(state) {return state.products},
        team(state) {return state.team},
        quotesEmailAddress(state) {return state.quotesEmailAddress},
        contactEmailAddress(state) {return state.contactEmailAddress},
        newBrokerEmailAddress(state) {return state.newBrokerEmailAddress},
        newBrokerContactNo(state) {return state.newBrokerContactNo},
    }
});

export default store