<template>
    <v-app-bar
        app
        dark
        shrink-on-scroll
        :height="fullHeight"

        class="black--text pa-0 ma-0 white"
        style="z-index: 1000"
    >
        <div
            class="d-flex flex-column"
            style="height: 100%; width: 100%"
        >
            <div
                class="d-flex flex-grow-1"
                style="width: 100%"
                :style="`background: linear-gradient( 110deg, rgba(255, 255, 255, 0) calc(${$parent.styles.paddingTop} + 30px), #a81916 calc(${$parent.styles.paddingTop} + 32px), rgba(168, 25, 22, 0.85) calc(100% - 15.5%) );`"
            >
                <div class="fill-height pa-1 mx-2">
                  <img
                      height="100%"
                      alt="logo"
                      :src="$parent.styles.paddingTop === `${fullHeight}px` ? '/images/logo.svg' : '/images/logo_only.svg'"
                  />
                </div>
                <div
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="flex-grow-1 green--text d-flex justify-center flex-column pl-11"
                >
                    <template v-if="$parent.styles.paddingTop === `${fullHeight}px`">
                        <div class="caption white--text text-center" v-if="showCovid" style="line-height: 1">
                            For official Government information about COVID-19, please visit
                            <a
                                href="https://sacoronavirus.co.za/"
                               target="_blank"
                                style="color: white"
                            >www.sacoronavirus.co.za</a>
                            <v-icon
                                small
                                right
                                style="cursor: pointer"
                                @click="showCovid = false"
                            >mdi-close</v-icon>
                        </div>
                        <div class="d-flex justify-end align-center px-2">
                            <div class="text-h5 font-weight-regular pr-2 white--text">
                               Give us a <b>call</b> on <b>015 307 5587</b> or
                            </div>
                            <v-btn color="white" class="ma-0 pa-0 px-2 mr-2 primary--text" @click="$vuetify.goTo('#contactUs')">
                                <span class="text-h5 font-weight-bold" style="text-transform: none">Let us call you</span>
                            </v-btn>
                        </div>
                        <div class="secondary lighten-1 my-3" style="border-radius: 1px; height: 2px"></div>
                    </template>
                    <div class="d-flex pl-4">
                        <v-btn
                            v-for="(page, i) in pages"
                            :key="i"
                            :text="page.id !== curPage"
                            :outlined="page.id === curPage"
                            @click="setCurPage(page.id)"
                            class="mr-1"
                        >
                            <span style="text-transform: none">{{page.title}}</span>
                        </v-btn>
                    </div>
                </div>
                <div v-else class="d-flex justify-end pr-5 align-center" style="width: 100%">
                    <v-menu
                        bottom
                        offset-y
                        close-on-content-click
                        close-on-click
                    >
                        <template v-slot:activator="{ attrs, on }">
                            <v-btn
                                v-on="on"
                                v-bind="attrs"
                                fab
                                text
                                elevation="0"
                            >
                                <v-icon large>mdi-menu</v-icon>
                            </v-btn>
                        </template>
                        <v-list
                            v-for="(btn, i) in pages"
                            :key="i"
                        >
                            <v-btn
                                text
                                width="100%"
                                @click="setCurPage(btn.id)"
                            >{{ btn.title }}</v-btn>
                        </v-list>
                    </v-menu>
                </div>
            </div>
        </div>
    </v-app-bar>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {
            console: console,
            showCovid: true
        }
    },
    methods: {
        ...mapActions(["setCurPage"])
    },
    computed: {
        ...mapGetters(["curPage", "pages"]),
        fullHeight() {
            return 128//this.showCovid ? 128 + 50 : 128
        },
        getScreenWidth() {
            return window.screen.width;
        }
    },
}
</script>

<style scoped>
.header-back {
    background-image: url("/images/Wave Line (1).svg");
    background-size: cover;
    background-position: center;
}

.headerBackSlim {
  background: linear-gradient(
      110deg,
      rgba(255, 255, 255, 0) 80px,
      #a81916 80px,
      rgba(168, 25, 22, 0.95) calc(100% - 15.5%)
  ) top;
  overflow-y: visible;
}

.headerBack {
  background: linear-gradient(
      110deg,
      rgba(255, 255, 255, 0) 155px,
      #a81916 157px,
      rgba(168, 25, 22, 0.95) calc(100% - 15.5%)
  ) top;
  overflow-y: visible;
}
</style>