import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import './main.scss';

Vue.config.productionTip = false

import store from './store'

new Vue({
  vuetify,
  render: h => h(App),
  store: store
}).$mount('#app');

// Load Data Into The Store

fetch("/siteData.json")
    .then(r => r.json())
    .then(json => {
          store.dispatch('setDynamicData', json)
        },
        response => {
          console.log('Error loading json:', response)
        });
