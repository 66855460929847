<template>
    <div style="position: relative">
        <div
            :class="`${$vuetify.breakpoint.lgAndUp ? '' : 'd-flex justify-center' }`"
            :style="`right: 0; top: 0; ${$vuetify.breakpoint.lgAndUp ? 'position: absolute; width: 30%' : 'width: 100%' }`"
        >
            <img
                :width="`${$vuetify.breakpoint.lgAndUp ? '' : '500' }`"
                alt="products"
                src="/images/documents.svg"
            />
        </div>
        <div :style="`${$vuetify.breakpoint.mdAndUp ? 'margin-left: 100px' : '' }`">
          
          <template v-for="section in otherDocs">
            
            <div :key="section.title" class="pb-4">
              <div class="py-2 text-h5">{{section.title}}</div>
              <div class="pl-4">
                <template v-for="(doc, i) in section.children">
                  <a :href="`/documents/${doc.filename}`" :key="i" style="text-decoration: none" target="_blank">
                    <div
                        class="d-flex align-center py-2 px-6"
                        style="cursor: pointer"
                    >
                      <div class="pr-3">
                        <v-icon color="primary">mdi-file-document-outline</v-icon>
                      </div>
                      <div :class="`${$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'text-h6'} font-weight-light`">{{doc.title}}</div>
                    </div>
                  </a>
                </template>
              </div>
            </div>
          </template>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    data() {
        return {
        }
    },
    components: {
    },
    computed: {
        ...mapGetters(['team', 'otherDocs', 'curPage', 'pages'])
    }
}
</script>

<style scoped>
.home {
    background-image: url("/images/background.svg");
    background-position: bottom;
    background-size: contain;
}
</style>