<template>
    <div>
        <div
            v-for="(art, i) in news"
            :key="i"
            class="d-flex justify-center py-2"
        >
            <div
                :class="`d-flex align-start ${$vuetify.breakpoint.mdAndUp ? '' : 'flex-column justify-center align-center' }`"
                :style="`${$vuetify.breakpoint.mdAndUp ? 'width: 80%; min-width: 720px; max-width: 1024px' : '' }`"
            >
                <v-card  :elevation="$vuetify.breakpoint.mdAndUp ? '' : '0'">
                    <img
                        :width="$vuetify.breakpoint.mdAndUp ? '400' : '375'"
                        alt="Dirt Dash"
                        :src="`/images/news/${ art.image }`"
                    />
                </v-card>
                <div class="px-4 pt-4">
                    <div class="d-flex align-start justify-space-between">
                        <div class="text-h4 pt-2 pb-2 font-weight-light">{{ art.title }}</div>
                        <div class="text-caption grey--text">{{ art.date }}</div>
                    </div>
                    <div v-html="art.body" class="text-caption"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    props: {
        propName: {
            type: Number,
            default: 1
        },
    },
    computed: {
        ...mapGetters(["news"])
    }
}
</script>