<template>
  <div ref="topElement">
    <div
        class="mx-2 mx-md-0 d-flex align-top justify-center home"
        style="padding-bottom: 10%; margin-bottom: -50px"
    >
      <div
          class="pa-2"
          style="max-width: 800px; width: 100%"
      >
        <div class="text-h4 font-weight-black primary--text pa-2">Get a Personalised Insurance Quote</div>
        <v-card
            :elevation="15"
            class="pa-5"
        >
          <template v-if="!mailSent">
            <v-form
                ref="sendMail"
                :disabled="sendingMail"
                @submit.prevent.stop
                class="fill-height of-y"
            >
              <div class="pb-2 d-md-flex">
                <v-autocomplete
                    :items="[ 'Mr', 'Ms', 'Mrs', 'Miss', 'Dr', 'Prof', 'Rev', 'Pas']"
                    label="Title"
                    class="mx-2 d-md-flex"
                    :rules="[validationRules.required]"
                    v-model="title"
                ></v-autocomplete>
                <v-text-field
                    class="flex-grow-1 mx-2"
                    label="Name"
                    placeholder="e.g. John"
                    :rules="[validationRules.required]"
                    v-model="name"
                ></v-text-field>
                <v-text-field
                    class="flex-grow-1 mx-2"
                    label="Surname"
                    placeholder="e.g. Smith"
                    :rules="[validationRules.required]"
                    v-model="surname"
                ></v-text-field>
              </div>
              <div class="pb-2 d-md-flex">
                <v-text-field
                    class="flex-grow-1 mx-2"
                    label="Contact No."
                    placeholder="e.g. 015 307 5587"
                    :rules="[validationRules.required, validationRules.cellNo]"
                    v-model="contactNo"
                ></v-text-field>
                <v-text-field
                    class="flex-grow-1 mx-2"
                    label="Email Address"
                    placeholder="e.g. info@smitk.co.za"
                    :rules="[validationRules.required, validationRules.email]"
                    v-model="contactEmail"
                ></v-text-field>
                <v-text-field
                    class="flex-grow-1 mx-2"
                    label="RSA ID / Passport No."
                    placeholder="e.g. 9110165165094"
                    :rules="[validationRules.required, validationRules.min13Characters]"
                    v-model="idNo"
                ></v-text-field>
              </div>
              <div class="d-flex justify-center justify-md-end mb-3">
                <v-btn
                    large
                    color="primary"
                    @click="sendEmail"
                    :disabled="sendingMail"
                    :loading="sendingMail"
                >
                  <v-icon left>mdi-phone</v-icon>
                  Give me a call
                </v-btn>
              </div>
            </v-form>
            <div class="caption grey--text text-center">
              We understand how important your privacy is and will protect your personal information. By providing your personal information you are agreeing that we may process it to furnish you with a quote. Also note that some advisors work under supervision.  This is to ensure that you’ll get nothing but awesome service, all of the time! Premiums quoted are risk profile dependent and reviewed annually based on risk profile and economic factors. Ts, Cs and limits apply. It is important to provide us with correct information, otherwise it could affect the outcome of your claim.
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-center">
              <img alt="We Will Be In Contact" src="/images/wait.svg" width="50%">
              <div
                  class="text-center text-h5 primary--text pa-2"
                  style="width: 50%"
              >
                Thank you
                <br>
                <br>
                We will be in contact shortly
              </div>
            </div>
          </template>
        </v-card>
      </div>
    </div>
    <div :class="`d-flex align-center justify-center ${$vuetify.breakpoint.mdAndUp ? 'bannerLarge' : 'bannerSmall' }`">
      <div class="my-4 white--text pa-8" style="max-width: 1275px">
          <div class="text-h4 text-center pb-4">Why Us?</div>
          <div class="font-weight-light pt-2 d-flex align-center">
            <v-icon left color="white">mdi-circle-small</v-icon>
            Smit and Kie Brokers acts with the utmost level of honesty and integrity when providing professional and accurate advice, by managing your risks with products according to the latest market trends supported by one of our qualified and competent staff members.</div>
          <div class="font-weight-light pt-2 d-flex align-center">
            <v-icon left color="white">mdi-circle-small</v-icon>
            We provide you with the best possible cover money can buy with the most affordable premiums adding our world class service. We place your needs first, as we always value you the client as the most important asset of our business.</div>
          <div class="font-weight-light pt-2 d-flex align-center">
            <v-icon left color="white">mdi-circle-small</v-icon>
            We operate strictly according to legislation as prescribed by government and keep updated with the latest changes in legislation to ensure that we always comply. Therefore your personal information and interests is protected at all times.</div>
          <div class="font-weight-light pt-2 d-flex align-center">
            <v-icon left color="white">mdi-circle-small</v-icon>
            We strive towards making a difference towards our clients by walking the extra mile during claim stage. We understand the importance of settling claims as quickly as possible by looking for reasons to settle your claims in the most convenient manner possible.</div>
      </div>
    </div>
    <div
        class="d-flex justify-md-center"
        style="margin-bottom: 50px"
    >
      <div class="d-flex flex-grow-1 flex-md-grow-0 justify-center align-center" style="width: 80%">
        <div class="d-flex flex-column justify-center align-center" :style="` ${$vuetify.breakpoint.mdAndUp ? 'width: 60%' : 'width: 100%' }`">
          <div class="d-md-none" style="width: 300px; min-width: 300px">
            <img alt="Customer Satisfaction" src="/images/customer_satisfaction.svg"/>
          </div>
          <div class="text-h4 font-weight-medium text-center " style="line-height: 1.5em">
            Our clients rate us an average of <strong>96.7%</strong> when asked about our <strong>friendliness</strong>, <strong>service</strong> and claim <strong>turnaround time</strong>.
          </div>
          <div class="pt-5 text-caption grey--text font-weight-regular text-center">
            * Based on feedback from surveys done by a 3rd party insurer to measure customer satisfaction during July 2021 - August 2021.
          </div>
        </div>
        <div class="ml-15 hidden-md-and-down" style="width: 300px; min-width: 300px">
          <img alt="Customer Satisfaction" src="/images/customer_satisfaction.svg"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import Footer from './Footer'
import {mapGetters} from "vuex";

export default {
    components: {
        // Footer: Footer,
    },
    data() {
        return {
            validationRules: {
                required: value => !!value || 'Required Value',
                min13Characters: value => !!value && value.length >= 13 || 'Minimum 13 Characters',
                notZero: value => value === '0' || value === '' || value == null ? 'Value can not be zero' : true,
                notNull: value => value !== null || 'Required Value',
                min6Characters: value => value !== null && value.split('').length >= 6 || 'Minimum 6 characters',
                specialCharacter: value => value !== null && /[^A-Za-z0-9]/.test(value) || 'Minimum 1 special character (eg. !@#$%)',
                upperCase: value => value !== null && /[A-Z]/.test(value) || 'Minimum 1 uppercase character',
                lowerCase: value => value !== null && /[a-z]/.test(value) || 'Minimum 1 lowercase character',
                numeric: value => value !== null && /[0-9]+/.test(value) || 'Minimum 1 numerical character',
                numberOnly: value => value !== null && /^[\d\\.]+$/.test(value) || 'Only numbers allowed',
                wholeNumberOnly: value => value !== null && /^[\d]+$/.test(value) || 'Only whole numbers allowed',
                email: value => {
                    if (!!value && value !== null && value.length > 0) {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'Invalid Email Address';
                    } else {
                        return 'Please enter a valid email address'
                    }
                },
                cellNo: value => value !== null && /[0-9+ ][^A-Za-z]{9,}/.test(value) || 'Please enter a valid phone number',
            },
            sendingMail: false,
            mailSent: false,
            title: null,
            name: null,
            surname: null,
            contactNo: null,
            contactEmail: null,
            idNo: null,
        }
    },
    methods: {
        sendEmail() {
            if (this.$refs.sendMail.validate()) {
                this.sendingMail = true;
                let mailBody = `<table style="font-family: sans-serif;">
                    <tr>
                        <td style="padding: 8px" colSpan="2" style="font-size: 18px">New Quote Request From Website</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px"><strong>Full Name:</strong></td>
                        <td style="padding: 8px">${this.title} ${this.name} ${this.surname}</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px"><strong>Contact No:</strong></td>
                        <td style="padding: 8px">${this.contactNo}</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px"><strong>Contact Email:</strong></td>
                        <td style="padding: 8px">${this.contactEmail}</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px"><strong>ID - Passport No:</strong></td>
                        <td style="padding: 8px">${this.idNo}</td>
                    </tr>
                </table>`
                Email.send({
                    SecureToken : "0a0cd794-a296-40fe-b688-973763c30498",
                    To : this.quotesEmailAddress,
                    From : "website@smitk.co.za",
                    Subject : "[SK WEBSITE] New Quote",
                    Body : mailBody
                }).then(() => {
                    this.sendingMail = false;
                    this.mailSent = true;
                });
            }
        }
    },
    computed: {
        ...mapGetters(['quotesEmailAddress'])
    },
}
</script>

<style scoped>
    .home {
        background-image: url("/images/background.svg");
        background-position: bottom;
        background-size: contain;
    }
    .bannerLarge {
      background: linear-gradient(
          178deg,
          rgba(0, 0, 0, 0) 15%,
          #a81916 15.5%,
          #bf5654 calc(100% - 15.5%),
          rgba(0, 0, 0, 0) calc(100% - 15%)
      ) local;
      min-height: 400px;
      overflow-y: visible;
      padding-top: 70px;
      padding-bottom: 70px;
    }
    .bannerSmall {
      background: linear-gradient(
          176deg,
          rgba(0,0,0,0) 11.5%,
          rgba(168,25,22,1) 12%,
          rgba(191,86,84,1) calc(100% - 12%),
          rgba(0,0,0,0) calc(100% - 11.5%)
      );
      min-height: 400px;
      overflow-y: visible;
      padding-top: 70px;
      padding-bottom: 70px;
    }
</style>
