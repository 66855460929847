<template>
  <v-app class="secondary lighten-5">
    <v-main class="secondary lighten-5">
        <div
            id="topOfPage"
            class="secondary lighten-5"
            style="height: 100vh"
        >
            <Header class="white"/>
            <home v-if="curPage === 'home'" class="secondary lighten-5"/>
            <products v-if="curPage === 'products'" class="secondary lighten-5"/>
            <team v-if="curPage === 'team'" class="secondary lighten-5"/>
            <find-a-broker v-if="curPage === 'findBroker'" class="secondary lighten-5"/>
            <partner v-if="curPage === 'becomePartner'" class="secondary lighten-5"/>
            <about v-if="curPage === 'about'" class="secondary lighten-5"/>
            <documents v-if="curPage === 'additionalDocs'" class="secondary lighten-5"/>
            <other-documents v-if="curPage === 'otherDocs'" class="secondary lighten-5"/>
            <smit-advisors v-if="curPage === 'smitAdvisors'" class="secondary lighten-5"/>
            <news-main v-if="curPage === 'news'" class="secondary lighten-5"/>
            <Footer/>
        </div>
    </v-main>
  </v-app>
</template>

<script>
import Home from "./components/Home";
import {mapGetters} from "vuex";
import Products from "./components/Products";
import Team from "./components/Team";
import Header from "./components/Header";
import FindABroker from "./components/FindABroker";
import Partner from './components/partner'
import Footer from './components/Footer'
import About from "./components/About";
import Documents from "./components/Documents";
import SmitAdvisors from "./components/SmitAdvisors";
import NewsMain from "./components/News/NewsMain";
import OtherDocuments from "./components/OtherDocuments.vue";

export default {
  name: 'App',
  components: {
      NewsMain,
      SmitAdvisors,
      About,
      Partner,
      FindABroker,
      Header,
      Products,
      Home,
      Team,
      Footer,
      Documents,
      OtherDocuments
  },
  data() {
    return {

    }
  },
  watch: {
    curPage() {
        this.$nextTick(this.$vuetify.goTo(0))
    }
  },
  computed: {
    ...mapGetters(['curPage'])
  },
};
</script>