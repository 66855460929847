<template>
    <v-card
        :hover="$vuetify.breakpoint.mdAndUp"
        class="d-flex flex-column justify-center align-center mb-5 white pa-2"
        :style="`z-index: 99; ${$vuetify.breakpoint.mdAndUp ? 'max-width: 200px' : 'max-width: 188px' }`"
    >
<!--        style="z-index: 99; max-width: 200px"-->
        <v-card
            class="mt-2"
            style="width: 100px; height: 100px; overflow: hidden; background-position: center; background-size: cover; border-radius: 50px; border: 3px solid white"
            :style="`background-image: url('./avatars/${broker.image || 'none.svg'}')`"
        ></v-card> <!-- Image -->
        <div class="mt-2 d-flex flex-column align-center">
            <div class="text-center px-2">{{ broker.title }}</div>
            <div
                v-if="broker.number"
                class="pt-1 text-center caption d-flex align-center"
            >
                <v-icon
                    small
                    class="pr-1"
                >mdi-phone</v-icon>
                {{ broker.number }}
            </div>
            <div
                v-if="broker.email"
                class="pt-1 text-center caption d-flex align-center"
            >
                <v-icon
                    small
                    class="pr-1"
                >mdi-at</v-icon>
                {{ broker.email }}
            </div>
            <div class="pt-1 text-center caption">
                <v-icon
                    small
                    class="pr-1"
                >mdi-mail</v-icon>
                {{ broker.office.location }}
            </div>
<!--            <div v-if="broker.subTitle" class="text-center caption mt-1 px-2" style="background: rgba(255, 249, 247, 0.75); border-radius: 5px; white-space: pre">-->
<!--                {{broker.subTitle}}-->
<!--            </div>-->
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        broker: Object
    },
}
</script>