import { render, staticRenderFns } from "./partner.vue?vue&type=template&id=60af1d9e&scoped=true&"
import script from "./partner.vue?vue&type=script&lang=js&"
export * from "./partner.vue?vue&type=script&lang=js&"
import style0 from "./partner.vue?vue&type=style&index=0&id=60af1d9e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60af1d9e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VForm,VIcon,VTextField,VTextarea})
