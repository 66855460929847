<template>
    <div :class="`d-flex flex-column align-center ${ $vuetify.breakpoint.mdAndUp ? 'partnerLarge' : 'partnerSmall' }`">
        <div
            class="text-center text-h3 primary--text pt-md-8 pt-5 px-5"
            style="max-width: 700px"
        >
            Become a Partner Broker
            <div class="text-h5 font-weight-light grey--text pt-3">
                Our team is happy to answer your questions. Fill out the form and we will be in touch as soon as
                possible.
            </div>
        </div>
        <template v-if="!messageSent">
            <div
                class="d-flex flex-column flex-grow-1"
                style="width: 100%"
            >
                <div class="d-md-flex justify-center">
                    <div class="hidden-md-and-up pa-10 white--text"
                         style="width: 100%"                    >
                        <div
                            class="text-center text-h3 pb-3"
                            style="margin-top: 70px"
                        >
                            <div style="display: inline-block; position: relative">
                                <v-icon
                                    color="white"
                                    style="font-size: 50px; position: absolute; top: -30px; right: -60px"
                                >mdi-message-question-outline</v-icon>
                                Why Us
                            </div>
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            One place of entrance and communication and you will have contracts to various insurers
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            We can design a unique policy to suit your client’s needs
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            Simplified administration
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            A special team dedicated to helping you
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            Monitor compliance on your behalf
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            Provides training
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            Can negotiate rates to what you need to place the business in most cases
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            You are not a one-man business, you have the strength of the INSBRO group to not be bullied and
                            caged by insurers
                        </div>
                    </div>
                    <div
                        class="pa-10"
                        :style="`${$vuetify.breakpoint.mdAndUp ? 'width: 550px' : 'width: 100%' }`"
                    >
                        <v-form
                            ref="sendMail"
                            @submit.prevent=""
                            class="fill-height of-y"
                            :disabled="sendingMail"
                        >
                            <v-text-field
                                class="flex-grow-1 mx-2 mt-2"
                                label="Full Name"
                                solo
                                background-color="white"
                                hide-details="auto"
                                placeholder="e.g. John Smith"
                                :rules="[validationRules.required]"
                                v-model="name"
                            ></v-text-field>
                            <v-text-field
                                class="flex-grow-1 mx-2 mt-2"
                                label="RSA ID / Passport No."
                                solo
                                background-color="white"
                                hide-details="auto"
                                placeholder="e.g. 9110165165094"
                                :rules="[validationRules.required, validationRules.min13Characters]"
                                v-model="idNo"
                            ></v-text-field>
                            <v-text-field
                                class="flex-grow-1 mx-2 mt-2"
                                label="Email Address"
                                solo
                                background-color="white"
                                hide-details="auto"
                                placeholder="e.g. info@smitk.co.za"
                                :rules="[validationRules.required, validationRules.email]"
                                v-model="contactEmail"
                            ></v-text-field>
                            <v-text-field
                                class="flex-grow-1 mx-2 mt-2"
                                label="Contact No."
                                solo
                                background-color="white"
                                hide-details="auto"
                                placeholder="e.g. 015 307 5587"
                                :rules="[validationRules.required, validationRules.cellNo]"
                                v-model="contactNo"
                            ></v-text-field>
                            <v-textarea
                                class="flex-grow-1 mx-2 mt-2"
                                label="Physical Address"
                                solo
                                rows="2"
                                auto-grow
                                background-color="white"
                                hide-details="auto"
                                placeholder="10 Windsor Street | Tzaneen | 0850"
                                :rules="[validationRules.required]"
                                v-model="address"
                            ></v-textarea>
                            <v-textarea
                                class="flex-grow-1 mx-2 mt-2"
                                label="Estimated Book Size"
                                solo
                                rows="2"
                                auto-grow
                                background-color="white"
                                hide-details="auto"
                                placeholder="Estimated Book Size And Notes"
                                :rules="[validationRules.required]"
                                v-model="size"
                            ></v-textarea>
                            <v-textarea
                                class="flex-grow-1 mx-2 mt-2"
                                label="Preferred Insurers"
                                solo
                                rows="2"
                                auto-grow
                                background-color="white"
                                hide-details="auto"
                                placeholder="Renasa, Santam, OMI..."
                                :rules="[validationRules.required]"
                                v-model="insurers"
                            ></v-textarea>
                            <div class="text-center pa-4">
                                <v-btn
                                    color="white"
                                    outlined
                                    large
                                    :disabled="sendingMail"
                                    :loading="sendingMail"
                                    @click="sendEmail"
                                >
                                    <v-icon left color="white">mdi-account-voice</v-icon>
                                    Lets Talk
                                </v-btn>
                            </div>
                        </v-form>
                    </div>
                    <div class="hidden-md-and-down pa-10 white--text"
                         style="width: 50%"
                    >
                        <div
                            class="text-center text-h3 pb-3"
                            style="margin-top: 70px"
                        >
                            <div style="display: inline-block; position: relative">
                                <v-icon color="white"
                                        style="font-size: 50px; position: absolute; top: -30px; right: -60px"
                                >mdi-message-question-outline</v-icon>
                                Why Us
                            </div>
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            One place of entrance and communication and you will have contracts to various insurers
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            We can design a unique policy to suit your client’s needs
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            Simplified administration
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            A special team dedicated to helping you
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            Monitor compliance on your behalf
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            Provides training
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            Can negotiate rates to what you need to place the business in most cases
                        </div>
                        <div class="pb-2 d-flex align-center">
                            <v-icon color="white" left>mdi-circle-small</v-icon>
                            You are not a one-man business, you have the strength of the INSBRO group to not be bullied and
                            caged by insurers
                        </div>
                    </div>
                </div>
                <div class="text-center white--text text-h5 pa-8">
                    Excited and can't wait, got any questions about becoming a <strong>broker</strong>? Submit the form or call
                    us on <strong>{{ newBrokerContactNo }}</strong>.
                </div>
            </div>
        </template>
        <template v-else>
            <div class="d-flex justify-center flex-column align-center of-y-f flex-grow-1" style="margin-top: 40px">
                <img alt="partnership" src="/images/collaboration.svg" width="400px">
                <div class="text-h5 font-weight-light white--text pt-3">
                    <strong>Thank you</strong>! We will be in touch as soon as possible.
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            name: null,
            idNo: null,
            contactEmail: null,
            contactNo: null,
            address: null,
            size: null,
            insurers: null,
            messageSent: false,
            sendingMail: false,
            validationRules: {
                required: value => !!value || 'Required Value',
                min13Characters: value => !!value && value.length >= 13 || 'Minimum 13 Characters',
                notZero: value => value === '0' || value === '' || value == null ? 'Value can not be zero' : true,
                notNull: value => value !== null || 'Required Value',
                min6Characters: value => value !== null && value.split('').length >= 6 || 'Minimum 6 characters',
                specialCharacter: value => value !== null && /[^A-Za-z0-9]/.test(value) || 'Minimum 1 special character (eg. !@#$%)',
                upperCase: value => value !== null && /[A-Z]/.test(value) || 'Minimum 1 uppercase character',
                lowerCase: value => value !== null && /[a-z]/.test(value) || 'Minimum 1 lowercase character',
                numeric: value => value !== null && /[0-9]+/.test(value) || 'Minimum 1 numerical character',
                numberOnly: value => value !== null && /^[\d\\.]+$/.test(value) || 'Only numbers allowed',
                wholeNumberOnly: value => value !== null && /^[\d]+$/.test(value) || 'Only whole numbers allowed',
                email: value => {
                    if (!!value && value !== null && value.length > 0) {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'Invalid Email Address';
                    } else {
                        return 'Please enter a valid email address'
                    }
                },
                cellNo: value => value !== null && /[0-9+ ][^A-Za-z]{9,}/.test(value) || 'Please enter a valid phone number',
            },
        }
    },
    methods: {
        sendEmail() {
            if (this.$refs.sendMail.validate()) {
                this.sendingMail = true;
                let mailBody = `<table style="font-family: sans-serif;">
                    <tr>
                        <td style="padding: 8px" colSpan="2" style="font-size: 18px">New Broker Request From Website</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px"><strong>Name:</strong></td>
                        <td style="padding: 8px">${this.name.replace(/\n/g, '<br/>')}</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px"><strong>ID No:</strong></td>
                        <td style="padding: 8px">${this.idNo.replace(/\n/g, '<br/>')}</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px"><strong>Email:</strong></td>
                        <td style="padding: 8px">${this.contactEmail.replace(/\n/g, '<br/>')}</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px"><strong>Number:</strong></td>
                        <td style="padding: 8px">${this.contactNo.replace(/\n/g, '<br/>')}</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px"><strong>Address:</strong></td>
                        <td style="padding: 8px">${this.address.replace(/\n/g, '<br/>')}</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px"><strong>Book Size / Notes:</strong></td>
                        <td style="padding: 8px">${this.size.replace(/\n/g, '<br/>')}</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px"><strong>Insurers:</strong></td>
                        <td style="padding: 8px">${this.insurers.replace(/\n/g, '<br/>')}</td>
                    </tr>
                </table>`
                Email.send({
                    SecureToken : "0a0cd794-a296-40fe-b688-973763c30498",
                    To : this.newBrokerEmailAddress,
                    From : "website@smitk.co.za",
                    Subject : "[SK WEBSITE] New Broker",
                    Body : mailBody
                }).then(() => {
                    this.sendingMail = false;
                    this.messageSent = true;
                });
            }


        }
    },
    computed: {
        ...mapGetters(['newBrokerContactNo', 'newBrokerEmailAddress'])
    }
}
</script>

<style scoped>
    .partnerLarge {
        background: linear-gradient(
            170deg,
            rgba(0, 0, 0, 0) 400px,
            #a81916 402px,
            #bf5654
        ) local;
    }
    .partnerSmall {
        background: linear-gradient(
            170deg,
            rgba(0, 0, 0, 0) 18%,
            #a81916 19%,
            #bf5654 calc(100% - 15%),
            #bf5654 calc(100% - 5%)
        ) local;
    }
</style>